import { request } from "/src/utils/index.js";

export function Search(params) {
  return request.get("sys/club/menu/list", { params });
}

export function Detail(params) {
  return request.get("sys/club/menu" + params);
}

export function Add(params) {
  return request.post("sys/club/menu", params);
}

export function Update(params) {
  return request.put("sys/club/menu", params);
}

export function Delete(data) {
  return request.delete("sys/club/menu", { data });
}
